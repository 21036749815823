<template>
  <v-container class="">
    <v-row>
      <v-col>
        <GmapMap
          ref="mymap"
          :center="mapCenter"
          :zoom="mapZoom"
          style="width: 100%; height: 72vh"
        >
          <GmapMarker
            v-for="(item, index) in markets"
            :key="index"
            :icon="'http://maps.google.com/mapfiles/ms/icons/green-dot.png'"
            :position="item.coordenadas"
            :draggable="false"
            @click="fnGetGuia(item.id)"
          />
        </GmapMap>
      </v-col>
    </v-row>
    <!-- /*** */ -->
    <v-dialog scrollable v-model="dialog" persistent max-width="100%">
      <v-card>
        <v-card-title>Data kalan S.A.</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 90%">
          <!-- /** */ -->
          <cuerpo :guia="selectedGuia"></cuerpo>
          <!-- /** */ -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /*** */ -->
  </v-container>
</template>

<script>
import cuerpo from './../DashboardContent/modalInformacionDeGuiaCuerpo';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  components: {
    cuerpo,
  },
  props: ['prop_fecha', 'prop_departamento', 'prop_placa', 'prop_piloto'],
  data() {
    return {
      dialog: false,
      selectedGuia: [],
      mapCenter: { lat: 15.0032599, lng: -90.4136375 },
      markets: [
        // { id: 1, coordenadas: { lat: 15.0032599, lng: -90.4136375 } },
        // { id: 2, coordenadas: { lat: 14.5998377, lng: -90.5541501 } },
        // { id: 3, coordenadas: { lat: 14.6153348, lng: -90.6293886 } },
        // { id: 4, coordenadas: { lat: 14.8391533, lng: -91.556017 } },
        // { id: 5, coordenadas: { lat: 14.8512196, lng: -90.7805223 } },
        // {
        //   id: 6,
        //   coordenadas: {
        //     lat: 16.5609554,
        //     lng: -90.2365377,
        //   },
        // },
      ],
      mapTypeId: 'roadmap',
      mapZoom: 8,
      /** variables de paginado */
      page: 1,
      totalPages: 0,
      pageSize: 5,

      pageSizes: [5, 15, 50],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguiasMapa: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    fnGetGuia(id) {
      this.getguias(id).then((result) => {
        this.selectedGuia = result;
        this.dialog = true;
      });
    },
    getRequestParams() {
      /**
       * calculo de fechas para muestra de guias segun creadas
       */
      if (this.prop_fecha) {
        console.log('this dot fecha: ' + this.prop_fecha);
        let fecha_split = this.prop_fecha.split('-');

        let mes = fecha_split[1] - 1;
        let ano = fecha_split[0];

        var firstDay = new Date(ano, mes, 1);
        var lastDay = new Date(ano, mes + 1, 0);
      } else {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      /**
       * configuración de parametros
       */
      const params = {};
      params['$select'] = {
        _id: 1,
        guiaReceptor: 1,
        entrega: 1,
      };
      /**
       * paramentros de búsqueda por fecha
       */
      params['createdAt'] = {
        $gte: firstDay,
        $lte: lastDay,
      };

      /**
       * parametros para busqueda de Guias
       */

      params['guiaStatus'] = 'Entregado';

      if (this.searchGuiaCodigo != '') {
        params['guiaCodigo'] = this.searchGuiaCodigo;
      }

      if (this.prop_departamento != '') {
        params[
          'guiaReceptor.guiaReceptorDepartamento'
        ] = this.prop_departamento;
      }

      if (this.prop_placa != '' && this.prop_piloto != '') {
        params['quienCarga.pilotoPlaca'] = this.prop_placa;
        params['quienCarga.pilotoNombre'] = this.prop_piloto;
      }

      /**
       * paramentros de búsqueda por paginación (obligatorios)
       */
      params['$limit'] = 700;

      /**
       * fin de parametros para búsqueda de Guias
       */

      return params;
    },
  },
  mounted() {
    this.findguiasMapa({
      query: this.getRequestParams(),
    }).then((result) => {
      console.log('coordenadas de guia');
      result.data.forEach((guia) => {
        console.log(guia.entrega.coordenadas.coordinates);
        this.markets.push({
          id: guia._id,
          coordenadas: {
            lat: guia.entrega.coordenadas.coordinates[1],
            lng: guia.entrega.coordenadas.coordinates[0],
          },
        });
        // console.log(guia.guiaReceptor.guiaReceptorCoordenadas.coordinates);
      });
    });
  },
};
</script>
