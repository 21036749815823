var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[(!_vm.prop_fecha)?_c('v-card',{attrs:{"elevation":"3","max-width":""}},[_c('v-card-text',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.$store.state.globalMonth,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)},"update:return-value":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mostrando información del mes:","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formatDate),callback:function ($$v) {_vm.formatDate=$$v},expression:"formatDate"}},'v-text-field',attrs,false),on))]}}],null,false,3605585497),model:{value:(_vm.modalFecha),callback:function ($$v) {_vm.modalFecha=$$v},expression:"modalFecha"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"es"},model:{value:(_vm.$store.state.globalMonth),callback:function ($$v) {_vm.$set(_vm.$store.state, "globalMonth", $$v)},expression:"$store.state.globalMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.modalFecha = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.$store.state.globalMonth), _vm.main()}}},[_vm._v(" Mostrar ")])],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búscar por piloto","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"no-data-text":"Sin guias que mostrar","no-results-text":"No se encontrarón guias"},scopedSlots:_vm._u([{key:"item._id[0]",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"title":item,"target":"_blank","to":'/pilotos/' +
                item._id[0] +
                '/' +
                item._id[1] +
                '/' +
                _vm.dualCurrenMonthCheck}},[_c('v-chip',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"blue darken-4","outlined":"","pill":"","dark":""}},[_vm._v(" "+_vm._s(item._id[0])+" ")])],1)]}},{key:"item.porcentaje",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(
                  _vm.fnPorcentajeCalculado(
                    item.entregados,
                    item.anulados,
                    item.devueltos,
                    item.totalGuias
                  )
                ),"dark":""}},[_vm._v(" "+_vm._s(_vm.fnPorcentajeCalculado( item.entregados, item.anulados, item.devueltos, item.totalGuias ))+"% ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }