<template>
  <v-card>
    <!-- /** */ -->
    <v-toolbar color="primary" dark flat>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Información de: {{ $route.params.piloto }} -
            {{ $route.params.placa }} ({{ formatDate }})
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <!-- /** */ -->

    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <!-- /** */ -->
          <v-card-text v-if="item.tab == 'Graficas'">
            <v-row>
              <!-- /** grafica de pie (entregas del piloto) */ -->
              <v-col cols="6">
                <pilotoGraficas
                  :prop_placa="$route.params.placa"
                  :prop_piloto="$route.params.piloto"
                  :prop_fecha="$route.params.fecha"
                ></pilotoGraficas>
              </v-col>
              <!-- /** */ -->
              <!-- /** información ultimos pedidos realizados */ -->
              <v-col cols="6">
                <v-card>
                  <v-list subheader two-line v-if="entregas">
                    <!-- listado de ultimas guias "entregadas" -->
                    <v-list-item v-for="entrega in entregas" :key="entrega._id">
                      <v-list-item-avatar>
                        <v-icon
                          :class="storeGetColor(entrega.guiaStatus)"
                          dark
                          v-text="storeGetIcon(entrega.guiaStatus)"
                        ></v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-if="entrega.guiaReceptor"
                          v-text="entrega.guiaReceptor.guiaReceptorNombre"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          v-if="entrega.guiaReceptor"
                          v-text="entrega.guiaReceptor.guiaReceptorDireccion"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon color="grey lighten-1"
                            >mdi-information</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- listado de ultimas guias "entregadas" -->
                    <!-- /** cargar mas pedidos */ -->
                    <v-hover
                      v-if="entregas.length > 3"
                      v-slot:default="{ hover }"
                    >
                      <v-list-item
                        @click="tab = 2"
                        style="cursor: pointer"
                        :class="[
                          hover ? 'blue darken-2' : 'blue lighten-5',
                          'text-center',
                        ]"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            :class="
                              hover
                                ? 'white--text darken-2'
                                : 'blue--text lighten-5'
                            "
                            v-text="'Mostrar más guías'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-hover>

                    <!-- /** fin mas pedidos */ -->
                  </v-list>
                </v-card>
              </v-col>
              <!-- /** información ultimos pedidos realizados */ -->
            </v-row>
          </v-card-text>
          <!-- /**  MAPA DE ENTREGAS POR PILOTO */ -->
          <v-card-text v-if="item.tab == 'Mapa'"
            ><findMapa
              :prop_placa="$route.params.placa"
              :prop_piloto="$route.params.piloto"
              :prop_fecha="$route.params.fecha"
            ></findMapa></v-card-text
          ><!-- /**  MAPA DE ENTREGAS POR PILOTO */ -->

          <v-card-text v-if="item.tab == 'Detalle'"
            ><tblFindGuias
              :prop_placa="$route.params.placa"
              :prop_piloto="$route.params.piloto"
              :prop_fecha="$route.params.fecha"
            ></tblFindGuias></v-card-text
          ><!-- /** */ -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import pilotoGraficas from '../../components/chartjs/pilotoGraficas.vue';
import findMapa from './findMapa.vue';
import tblFindGuias from './../DashboardContent/DashboardTablaGuias';
import pilotos from './pilotos.vue';
//
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  components: {
    pilotoGraficas,
    findMapa,
    pilotos,
    tblFindGuias,
  },
  data() {
    return {
      entregas: [
        {
          titulo: 'Lupita Guitierrez Mack',
          icono: 'mdi-map-marker-check',
          color: 'success',
          subtitulo: 'Entregado: 24/05/2021 15:23',
        },
        {
          titulo: 'Maria Eugenia Palacio Montes',
          icono: 'mdi-map-marker-distance',
          color: 'pink',
          subtitulo: 'Cargado: 24/05/2021 14:59',
        },
        {
          titulo: 'Fabricio Jesus Esponosa Chui',
          icono: 'mdi-map-marker-distance',
          color: 'pink',
          subtitulo: 'Cargado: 24/05/2021 14:50',
        },
      ],
      //
      tab: null,
      items: [{ tab: 'Graficas' }, { tab: 'Mapa' }, { tab: 'Detalle' }],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    storeGetColor(guiaStatus) {
      if (guiaStatus) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == guiaStatus
        );

        return store_estado[0].color;
      }
    },
    storeGetIcon(guiaStatus) {
      if (guiaStatus) {
        let store_estado = this.$store.state.estados.filter(
          (store_estado) => store_estado.db_label == guiaStatus
        );

        return store_estado[0].icon;
      }
    },
  },
  mounted() {
    /**
     * calculo de fechas para muestra de guias segun creadas
     */
    if (this.$route.params.fecha) {
      let fecha_split = this.$route.params.fecha.split('-');

      let mes = fecha_split[1] - 1;
      let ano = fecha_split[0];

      var firstDay = new Date(ano, mes, 1);
      var lastDay = new Date(ano, mes + 1, 0);
    } else {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    /**
     *        */
    this.findguias({
      query: {
        'quienCarga.pilotoPlaca': this.$route.params.placa,
        'quienCarga.pilotoNombre': this.$route.params.piloto,
        createdAt: { $gte: firstDay, $lte: lastDay },
      },
    }).then((result) => {
      this.entregas = result.data;
      console.log(result);
    });
  },
  computed: {
    formatDate() {
      return this.$route.params.fecha
        ? moment(this.$route.params.fecha).format('MMMM-YYYY')
        : '';
    },
  },
};
</script>
