<template>
  <v-container class="">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <!-- /** inicio */ -->
            <v-card v-if="!prop_fecha" class="" elevation="3" max-width="">
              <v-card-text>
                <v-dialog
                  ref="dialog"
                  v-model="modalFecha"
                  :return-value.sync="$store.state.globalMonth"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      label="Mostrando información del mes:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                  <v-date-picker
                    v-model="$store.state.globalMonth"
                    type="month"
                    locale="es"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="modalFecha = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="green"
                      @click="
                        $refs.dialog.save($store.state.globalMonth), main()
                      "
                    >
                      Mostrar
                    </v-btn>
                  </v-date-picker>
                  <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                </v-dialog>
              </v-card-text>
            </v-card>
            <!-- /** fin */ -->

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búscar por piloto"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            no-data-text="Sin guias que mostrar"
            no-results-text="No se encontrarón guias"
          >
            <template v-slot:[`item._id[0]`]="{ item }">
              <router-link
                :title="item"
                target="_blank"
                :to="
                  '/pilotos/' +
                  item._id[0] +
                  '/' +
                  item._id[1] +
                  '/' +
                  dualCurrenMonthCheck
                "
                ><v-chip
                  style="cursor: pointer"
                  class="ma-2"
                  color="blue darken-4"
                  outlined
                  pill
                  dark
                >
                  {{ item._id[0] }}
                </v-chip></router-link
              >
            </template>
            <template v-slot:[`item.porcentaje`]="{ item }">
              <v-chip
                :color="
                  getColor(
                    fnPorcentajeCalculado(
                      item.entregados,
                      item.anulados,
                      item.devueltos,
                      item.totalGuias
                    )
                  )
                "
                dark
              >
                {{
                  fnPorcentajeCalculado(
                    item.entregados,
                    item.anulados,
                    item.devueltos,
                    item.totalGuias
                  )
                }}%
              </v-chip>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      /** monthPicker data begin */

      modalFecha: false,
      /** monthPicker data fin */
      search: '',
      headers: [
        {
          text: 'Piloto',
          align: 'start',
          sortable: false,
          value: '_id[1]',
        },
        { text: 'Placa', value: '_id[0]' },
        { text: 'En ruta', value: 'asignados' },
        { text: 'Entregados', value: 'entregados' },
        { text: 'Devueltos', value: 'devueltos' },
        { text: 'Anulados', value: 'anulados' },
        { text: 'Porcentaje', value: 'porcentaje' },
      ],
      desserts: [],
    };
  },
  props: ['prop_fecha', 'prop_departamento'],
  methods: {
    ...mapActions('DashboardResumenPorPiloto', {
      findDashboardResumenPorPiloto: 'find',
    }),
    ...mapActions('DashboardResumenPorPilotoyDepartamento', {
      findDashboardResumenPorPilotoyDepartamento: 'find',
    }),
    getColor(porcentaje) {
      if (porcentaje < 40) return 'red';
      else if (porcentaje < 70) return 'orange';
      else if (porcentaje < 85) return 'teal';
      else return 'green';
    },
    fnCargarTablaDetalleDePilotos() {
      /*********** por vista de pilotos  */
      console.log(
        'consultando informacion resumida de los pilotos (pilotos.vue 146)'
      );
      if (!this.prop_fecha) {
        this.findDashboardResumenPorPiloto({
          query: {
            fecha: this.$store.state.globalMonth,
          },
        }).then((result) => {
          console.log(result);
          this.desserts = result;
        });
      } else {
        /*********** por departamento */
        console.log(
          'consultando informacion por departamento (pilotos.vue 153)'
        );
        this.findDashboardResumenPorPilotoyDepartamento({
          query: {
            fecha: this.prop_fecha,
            departamento: this.prop_departamento,
          },
        }).then((result) => {
          console.log(result);
          this.desserts = result;
        });
      }
    },
    fnPorcentajeCalculado(entregados, devueltos, anulados, totalGuias) {
      let porcentaje = (
        ((entregados + anulados + devueltos) / totalGuias) *
        100
      ).toFixed(2);
      return porcentaje;
    },
    main() {
      this.fnCargarTablaDetalleDePilotos();
    },
  },
  computed: {
    formatDate() {
      return this.$store.state.globalMonth
        ? moment(this.$store.state.globalMonth).format('MMMM-YYYY')
        : '';
    },
    dualCurrenMonthCheck() {
      if (!this.prop_fecha) return this.$store.state.globalMonth;
      else return this.prop_fecha;
    },
  },
  mounted() {
    this.main();
  },
};
</script>
<style scoped>
.v-application a {
  color: #ffffff00 !important;
}
</style>
